import { createMutation } from "react-query-kit"
import { type GenericExtractor } from "shared-utils"

import {
  GET_ADDRESS_REVERSE_GEOCODE,
  GET_ADDRESS_REVERSE_GEOCODE_DEV,
} from "../../address/modal-add-address/federated/queries"
import {
  type GetAddressReverseGeocodeQuery,
  type GetAddressReverseGeocodeQueryVariables,
} from "../../federatedGql/graphql"
import { federatedGqlClient } from "../../utils/graphqlClient"

export type TGetAddressGeoResponse = GenericExtractor<
  GetAddressReverseGeocodeQuery["reverseGeocode"]
>

type TUseLazyReverseGeocode = {
  ignoreDistrictVillageFlagEnabled?: boolean
}

const useLazyReverseGeocode = ({
  ignoreDistrictVillageFlagEnabled,
}: TUseLazyReverseGeocode) => {
  const mutation = createMutation<
    TGetAddressGeoResponse,
    GetAddressReverseGeocodeQueryVariables
  >({
    mutationFn: async (variables) => {
      const input: GetAddressReverseGeocodeQueryVariables & {
        enrichData?: boolean
      } = {
        lat: variables.lat,
        lng: variables.lng,
      }

      if (ignoreDistrictVillageFlagEnabled) {
        input.enrichData = false
      }

      const res = await federatedGqlClient.request(
        ignoreDistrictVillageFlagEnabled
          ? GET_ADDRESS_REVERSE_GEOCODE_DEV
          : GET_ADDRESS_REVERSE_GEOCODE,
        input
      )

      if (res.reverseGeocode.__typename === "GenericError") {
        throw new Error(res.reverseGeocode.code)
      }
      return res.reverseGeocode
    },
  })

  return { mutation }
}

export default useLazyReverseGeocode
