import { cx } from "class-variance-authority"
import { useState, useRef, type FocusEvent, type ChangeEvent } from "react"
import { AlertTriangle, ChevronDown } from "react-feather"

import HeaderMenu from "./components/HeaderMenu"
import Options from "./components/Options"
import SkeletonLoading from "./components/SkeletonLoading"

import { type TInputSelectProps } from "./type"

import "./style.css"
import { Skeleton } from "shared-ui"

const InputSelect = ({
  options,
  onChange = () => undefined,
  id,
  label,
  onFocus,
  onBlur,
  onSelect,
  placeholder,
  isLoading,
  onClickCurrentLocation,
  onClickManualLocation,
  isClearFieldOnBlur,
  iconRight,
  iconLeft,
  errorMessage,
  defaultValue,
  onScrollItems,
  isInitialLoading,
  isErrorFetch,
  dropdownHeight,
  iconStyle,
  withChevron = true,
  ...props
}: TInputSelectProps) => {
  const inputRef = useRef<HTMLInputElement>(null)
  const isFirstRender = useRef<boolean>(true)
  const [isOpenDropDown, setIsOpenDropDown] = useState<boolean>(false)

  if (
    inputRef?.current?.value === "" &&
    isFirstRender?.current &&
    defaultValue
  ) {
    isFirstRender.current = false
    inputRef.current.value = defaultValue
  }

  const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    onChange(e.target.value)
    if (e.target.value) {
      setIsOpenDropDown(true)
    }
  }

  const handleBlur = (e: FocusEvent<HTMLInputElement>) => {
    onBlur && onBlur(e)
    if (isClearFieldOnBlur && inputRef && inputRef?.current) {
      inputRef.current.value = ""
    }
    if (
      e.relatedTarget === null ||
      (e.relatedTarget.id !== e.target.id &&
        !e.relatedTarget.id.includes("option"))
    ) {
      setIsOpenDropDown(false)
    } else {
      inputRef.current?.blur()
      setIsOpenDropDown(false)
    }
  }

  const handleOnFocus = (e: FocusEvent<HTMLInputElement>) => {
    const value = inputRef.current?.value
    e.preventDefault()
    if (onFocus) {
      onFocus(e)
      setIsOpenDropDown(true)
    }
    if (!isFirstRender?.current && value) onChange(value)
  }

  return (
    <section className="w-full">
      <div className="flex flex-col">
        {!!label && (
          <div className="mb-2">
            <label
              htmlFor={id}
              className="text-tertiary500 text-sm font-semibold"
            >
              {label}
            </label>
          </div>
        )}
        <div className="relative">
          {iconLeft && (
            <div
              className="rounded-l-4 absolute flex h-full items-center"
              style={{ top: 0, left: 0, ...iconStyle?.iconLeft }}
            >
              {iconLeft}
            </div>
          )}
          <input
            placeholder={placeholder || "Cari"}
            id={id}
            className={cx(
              "input rounded-4 focus:outline-tertiary200 disabled:bg-tertiary25 disabled:text-tertiary200 border text-sm",
              iconLeft ? "input-with-icon-left" : "input-without-icon-left",
              errorMessage ? "border-error500" : "border-tertiary50"
            )}
            ref={inputRef}
            onChange={handleOnChange}
            onFocus={handleOnFocus}
            onBlur={handleBlur}
            tabIndex={-1}
            {...props}
          />

          <div
            className="rounded-r-4 absolute right-3 flex h-full items-center"
            style={{ top: 0, ...iconStyle?.iconRight }}
          >
            {iconRight
              ? iconRight
              : withChevron && (
                  <ChevronDown
                    width={20}
                    height={20}
                    color="#344054"
                    className={isOpenDropDown ? "rotate-180" : "rotate-0"}
                  />
                )}
          </div>
        </div>
        <div
          className={cx(
            "flex items-center",
            errorMessage ? "justify-between" : "justify-end"
          )}
          style={{ gap: "56px" }}
        >
          {!!errorMessage && (
            <div className="rounded-l-4 left-0 top-0 mt-2 flex items-center justify-center">
              <AlertTriangle
                size={14}
                className="text-primary500 relative mr-1"
              />
              <div className="text-error500 text-xs">{errorMessage}</div>
            </div>
          )}
        </div>
      </div>
      {isOpenDropDown && (
        <div className="relative">
          <menu className="menu border-tertiary200 border border-solid">
            {onClickCurrentLocation && onClickManualLocation && (
              <HeaderMenu
                onClickCurrentLocation={onClickCurrentLocation}
                onClickManualLocation={onClickManualLocation}
                id={id}
              />
            )}
            <ul
              className={
                " w-full overflow-y-auto " +
                (dropdownHeight ? dropdownHeight : "max-h-26")
              }
              onScroll={onScrollItems}
            >
              {isInitialLoading && !isErrorFetch ? (
                <SkeletonLoading />
              ) : !options?.length || isErrorFetch ? (
                <div
                  className="flex w-full justify-center py-2 text-sm"
                  id={`${id}-no-options`}
                >
                  Tidak Ada Pilihan
                </div>
              ) : (
                <>
                  {options?.map((option, index) => (
                    <Options
                      id={id}
                      index={index}
                      key={index}
                      option={option}
                      onClick={() => {
                        onSelect(option)
                        if (inputRef && inputRef?.current) {
                          inputRef.current.blur()
                          inputRef.current.value = isClearFieldOnBlur
                            ? ""
                            : option.label
                        }
                      }}
                    />
                  ))}
                  {isLoading && (
                    <div className="list-menu mb-2 h-6 overflow-hidden">
                      <Skeleton bgColor="bg-tertiary50" />
                    </div>
                  )}
                </>
              )}
            </ul>
          </menu>
        </div>
      )}
    </section>
  )
}

export default InputSelect
