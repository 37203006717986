"use client"

import dynamic from "next/dynamic"
import { Search } from "react-feather"
import { useFormContext } from "react-hook-form"
import { Button, Spinner } from "shared-ui"
import { useSnackbar } from "shared-ui"

import useFormLevelAddAddressStore from "../../../address/hooks/useFormLevelAddAddressStore"
import { checkLocationPermission } from "../../../address/utils/checkLocationPermission"
import { GoogleSearchAutoComplete as GoogleSearchAutoCompleteV2 } from "../../../google-v2"
import { type TResultGoogleSearchAutoComplete as TResultGoogleSearchAutoCompleteV2 } from "../../../google-v2/google-search-autocomplete"
import HeaderForm from "../components/HeaderForm"
import { type TFormModalAdd } from "../type"

const LeafletSearchAutoComplete = dynamic(
  () => import("../../../leaflet-map/google-search-autocomplete/index"),
  {
    ssr: false,
    loading: () => (
      <div className="flex w-full justify-center">
        <Spinner />
      </div>
    ),
  }
)

type TFindAddressType = {
  onClickCancel: (val: boolean) => void
  id?: string
  isShowLeafletMap?: boolean
}

const FindAddress = ({
  onClickCancel,
  id = "add-address-findAddress",
  isShowLeafletMap,
}: TFindAddressType) => {
  const { enqueueSnackbar } = useSnackbar()
  const { setValue } = useFormContext<TFormModalAdd>()
  const setFormLevel = useFormLevelAddAddressStore((state) => state.setForm)

  const handleRequestLocation = async () => {
    await checkLocationPermission({
      isOnlyRequestPermission: false,
      callbackFn: ({ type, latLng }) => {
        if (type === "DENIED" || type === "INVALID") {
          const messageError = {
            DENIED:
              "Akses Lokasi terblokir, silahkan mengaktifkan di setting browser anda",
            INVALID:
              "Error: Fitur Akses lokasi saat ini tidak tersedia di browser anda",
          }
          return enqueueSnackbar({
            message: messageError[type],
            type: "error",
          })
        }

        if (latLng) {
          setValue("detailAddress.googleMaps.latLng", latLng)
        }
        setFormLevel("googleMaps")
      },
    })
  }

  const handleOnSelectSearch = ({
    addressName,
    addressSecondary,
    lat,
    lng,
    placeId,
  }: TResultGoogleSearchAutoCompleteV2) => {
    setValue("detailAddress.googleMaps", {
      addressName,
      addressSecondary,
      latLng: { lat, lng },
      placeId,
    })
    setFormLevel("googleMaps")
  }

  return (
    <>
      <HeaderForm className="mb-6" id={id} />
      <h5 className="text-tertiary500 my-4 text-base font-bold">
        Cari alamat pengiriman
      </h5>
      <p className="text-tertiary300 mb-4 text-sm">
        Lokasi tidak ditemukan?{" "}
        <span
          className="text-secondary500 cursor-pointer font-semibold"
          onClick={() => void handleRequestLocation()}
          id={`${id}-currentLocation`}
        >
          Gunakan Lokasi Saat Ini
        </span>
      </p>
      <fieldset>
        <div className="text-tertiary500 mb-2 text-sm font-semibold">
          Lokasi Tujuan Pengiriman
        </div>
        {isShowLeafletMap ? (
          <LeafletSearchAutoComplete
            id={id}
            onSelect={handleOnSelectSearch}
            onClickManualLocation={() => setFormLevel("manual")}
            onClickCurrentLocation={() => void handleRequestLocation()}
            placeholder="Tulis nama jalan / gedung/ perumahan"
            iconRight={<Search color="#818996" />}
            iconStyle={{
              iconRight: {
                right: 0,
                padding: "0px 14px",
                backgroundColor: "#E2E4E8",
              },
            }}
          />
        ) : (
          <GoogleSearchAutoCompleteV2
            id={id}
            onSelect={handleOnSelectSearch}
            onClickManualLocation={() => setFormLevel("manual")}
            onClickCurrentLocation={() => void handleRequestLocation()}
            placeholder="Tulis nama jalan / gedung/ perumahan"
            iconRight={<Search color="#818996" />}
            iconStyle={{
              iconRight: {
                right: 0,
                padding: "0px 14px",
                backgroundColor: "#E2E4E8",
              },
            }}
          />
        )}
      </fieldset>
      <fieldset>
        <Button
          id={`${id}-cancel`}
          variant="outline2"
          onClick={() => onClickCancel(true)}
          className="mb-3 mt-10 w-full"
        >
          Batal
        </Button>
      </fieldset>
    </>
  )
}

export default FindAddress
