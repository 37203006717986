import { graphql } from "../../../federatedGql/gql"

export const GET_ADDRESS_REVERSE_GEOCODE = graphql(`
  query getAddressReverseGeocode($lat: Float!, $lng: Float!) {
    reverseGeocode(query: { latitude: $lat, longitude: $lng }) {
      ... on ReverseGeocode {
        __typename
        cityAreaCode
        cityId
        cityName
        districtAreaCode
        districtId
        districtName
        provinceAreaCode
        provinceId
        provinceName
        villageAreaCode
        villageId
        villageName
        villagePostalCode
      }
      ... on GenericError {
        __typename
        code
        message
        reqId
      }
    }
  }
`)

export const GET_ADDRESS_REVERSE_GEOCODE_DEV = graphql(`
  query getAddressReverseGeocodeDev(
    $lat: Float!
    $lng: Float!
    $enrichData: Boolean
  ) {
    reverseGeocode(
      query: { latitude: $lat, longitude: $lng, enrichData: $enrichData }
    ) {
      ... on ReverseGeocode {
        __typename
        cityAreaCode
        cityId
        cityName
        districtAreaCode
        districtId
        districtName
        provinceAreaCode
        provinceId
        provinceName
        villageAreaCode
        villageId
        villageName
        villagePostalCode
      }
      ... on GenericError {
        __typename
        code
        message
        reqId
      }
    }
  }
`)

export const GET_SEARCH_REGION_V2 = graphql(`
  query GetSearchRegion(
    $level: RegionLevel
    $search: String
    $parentIds: [String!]
    $pagination: RegionPagination!
  ) {
    getSearchRegionV2(
      query: {
        filter: { level: $level, search: $search, parentIds: $parentIds }
        pagination: $pagination
      }
    ) {
      ... on RegionSearchList {
        items {
          areaCode
          id
          level
          name
          parentId
          postalCode
        }
        currentPage
        lastPage
        perPage
        total
      }
      ... on GenericError {
        __typename
        code
        message
        reqId
      }
    }
  }
`)
