"use client"

import {
  type UseInfiniteQueryOptions,
  useInfiniteQuery,
} from "@tanstack/react-query"
import { type GenericExtractor } from "shared-utils"

import {
  type GetSearchRegionQueryVariables,
  type GetSearchRegionQuery,
} from "../../federatedGql/graphql"
import { federatedGqlClient } from "../../utils/graphqlClient"
import { GET_SEARCH_REGION_V2 } from "../modal-add-address/federated/queries"

export type TGetRegionSearchResponse = GenericExtractor<
  GetSearchRegionQuery["getSearchRegionV2"]
>

type TUseGetRegionSearchPagination = {
  options?: UseInfiniteQueryOptions<TGetRegionSearchResponse>
  variables: GetSearchRegionQueryVariables
  onSuccess?: (data: TGetRegionSearchResponse, isPagination?: boolean) => void
}

const useGetRegionSearchPagination = ({
  variables,
  options,
  onSuccess,
}: TUseGetRegionSearchPagination) => {
  return useInfiniteQuery({
    queryKey: ["GET_SEARCH_REGION", variables],
    queryFn: async ({ pageParam = 1 }) => {
      const res = await federatedGqlClient.request(GET_SEARCH_REGION_V2, {
        search: variables.search,
        level: variables.level,
        parentIds: variables.parentIds,
        pagination: {
          page: pageParam as number,
          perPage: variables.pagination.perPage,
        },
      })
      if (res?.getSearchRegionV2?.__typename === "GenericError") {
        throw new Error(res.getSearchRegionV2.code)
      }

      onSuccess?.(res.getSearchRegionV2, pageParam > 1)
      return res.getSearchRegionV2
    },
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    getNextPageParam: (page) => {
      if (
        typeof page?.currentPage === "number" &&
        typeof page?.lastPage === "number" &&
        page?.currentPage < page?.lastPage
      ) {
        return page?.currentPage + 1
      }
    },
    retry(_, error) {
      if (error && (error as string).includes("EntityNotFound")) {
        return false
      }

      return true
    },
    ...options,
  })
}
export default useGetRegionSearchPagination
