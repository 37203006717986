import { useFormContext } from "react-hook-form"

import { type Region, RegionLevel } from "../../../federatedGql/graphql"
import { type TOption } from "../components/google/input-select/type"
import { InputFieldSelect } from "../components/input"
import { emptyRegionDefault } from "../constants"
import { type TFormModalAdd } from "../type"

type RegionType = "province" | "city" | "district" | "village" | "postalCode"

export type TFormRegionAutoSuggestProps = {
  disabledInputField?: {
    [key in RegionType]?: boolean
  }
}

const FormRegionAutoSuggest = ({
  disabledInputField,
}: TFormRegionAutoSuggestProps) => {
  const isDisabledVillage = disabledInputField?.village ?? true
  const isDisabledDistrict = disabledInputField?.district ?? true
  const isDisabledpostalCode = disabledInputField?.postalCode ?? true
  const isDisabledCity = disabledInputField?.city ?? true

  const {
    setValue,
    control,
    formState: { errors },
    clearErrors,
  } = useFormContext<TFormModalAdd>()

  const handleChange = (
    value: string,
    updatedRegion: RegionType,
    defaultRegions: RegionType[]
  ) => {
    setValue(`detailAddress.${updatedRegion}`, {
      name: value,
      id: "",
      areaCode: "",
    })

    defaultRegions.forEach((region) => {
      setValue(`detailAddress.${region}`, emptyRegionDefault)
    })
  }

  const handleSelect = (
    option: TOption,
    updatedRegion: RegionType,
    defaultRegions: RegionType[],
    data?: Pick<Region, "parentId" | "postalCode">
  ) => {
    setValue(`detailAddress.${updatedRegion}`, {
      name: option.label,
      id: option.value,
      areaCode: option.areaCode,
    })
    defaultRegions.forEach((region) => {
      setValue(`detailAddress.${region}`, emptyRegionDefault)
    })

    if (!!data && updatedRegion === "village") {
      setValue("detailAddress.postalCode.name", data?.postalCode || "")
    }
  }

  return (
    <>
      <fieldset className="mb-4 flex gap-3">
        <div className="w-full">
          <InputFieldSelect
            level={RegionLevel.Province}
            control={control}
            name="detailAddress.province.name"
            label="Provinsi"
            onChange={(val) =>
              handleChange(val, "province", [
                "city",
                "district",
                "village",
                "postalCode",
              ])
            }
            onSelect={(opt) =>
              handleSelect(opt, "province", [
                "city",
                "district",
                "village",
                "postalCode",
              ])
            }
            placeholder="Masukan Provinsi"
            errorMessage={errors.detailAddress?.province?.name?.message}
            clearErrors={clearErrors}
            disabled
            id="province-select"
            autoComplete="off"
          />
        </div>
        <div className="w-full">
          <InputFieldSelect
            level={RegionLevel.City}
            control={control}
            name="detailAddress.city.name"
            label="Kota/Kabupaten"
            watchValues={["detailAddress.province"]}
            onChange={(val) =>
              handleChange(val, "city", ["district", "village", "postalCode"])
            }
            onSelect={(opt) =>
              handleSelect(opt, "city", ["district", "village", "postalCode"])
            }
            placeholder="Masukan Kota"
            errorMessage={errors.detailAddress?.city?.name?.message}
            clearErrors={clearErrors}
            id="city-select"
            autoComplete="off"
            disabled={isDisabledCity}
          />
        </div>
      </fieldset>
      <fieldset className="mb-4 flex gap-3">
        <div className="w-full">
          <InputFieldSelect
            level={RegionLevel.District}
            control={control}
            name="detailAddress.district.name"
            label="Kecamatan"
            onChange={(val) =>
              handleChange(val, "district", ["village", "postalCode"])
            }
            onSelect={(opt) =>
              handleSelect(opt, "district", ["village", "postalCode"])
            }
            watchValues={["detailAddress.city"]}
            placeholder="Masukan Kecamatan"
            errorMessage={errors.detailAddress?.district?.name?.message}
            clearErrors={clearErrors}
            id="district-select"
            autoComplete="off"
            disabled={isDisabledDistrict}
          />
        </div>
        <div className="w-full">
          <InputFieldSelect
            level={RegionLevel.Village}
            control={control}
            name="detailAddress.village.name"
            label="Kelurahan"
            onChange={(val) => handleChange(val, "village", ["postalCode"])}
            onSelect={(opt, data) =>
              handleSelect(opt, "village", ["postalCode"], data)
            }
            watchValues={["detailAddress.district"]}
            placeholder="Masukan Kelurahan"
            errorMessage={errors.detailAddress?.village?.name?.message}
            clearErrors={clearErrors}
            id="village-select"
            autoComplete="off"
            disabled={isDisabledVillage}
          />
        </div>
      </fieldset>
      <fieldset className="mb-4 flex w-full gap-x-3">
        <InputFieldSelect
          control={control}
          name="detailAddress.postalCode.name"
          label="Kode Pos"
          onChange={(val) => setValue("detailAddress.postalCode.name", val)}
          watchValues={["detailAddress.village"]}
          onSelect={(opt) =>
            setValue("detailAddress.postalCode.name", opt.label)
          }
          placeholder="Masukan Kode Pos"
          maxLength={5}
          errorMessage={errors.detailAddress?.postalCode?.name?.message}
          clearErrors={clearErrors}
          id="postalCode-select"
          autoComplete="off"
          disabled={isDisabledpostalCode}
        />
      </fieldset>
    </>
  )
}

export default FormRegionAutoSuggest
