"use client"
import { zodResolver } from "@hookform/resolvers/zod"
import { useForm, FormProvider } from "react-hook-form"
import { Modal, type ModalProps } from "shared-ui"

import DetailAddressSection from "./form/DetailAddressSection"
import FindAddress from "./form/FindAddressSection"
import { type TFormRegionAutoSuggestProps } from "./form/FormRegionAutoSuggest"
import GoogleMapsSection from "./form/GoogleMapsSection"
import ManualFormAddressSection from "./form/ManualAddressSection"

import { initForm } from "./constants"
import { addressSchema } from "./schema/addressSchema"
import { type TFormModalAdd } from "./type"
import useFormLevelAddAddressStore from "../hooks/useFormLevelAddAddressStore"

type ModalAddAdressProps = Omit<ModalProps, "children"> & {
  handleSubmitAddress: (address: TFormModalAdd) => void
  initialData?: TFormModalAdd
  loading: boolean
  onClose?: () => void
  withFormRegion?: boolean
  isShowLeafletMap?: boolean
  ignoreDistrictVillageFlagEnabled?: boolean
} & TFormRegionAutoSuggestProps

const ModalAddAddress = ({
  onClose,
  handleSubmitAddress,
  initialData,
  loading,
  withFormRegion,
  isShowLeafletMap,
  disabledInputField,
  ignoreDistrictVillageFlagEnabled,
  ...props
}: ModalAddAdressProps) => {
  const methods = useForm<TFormModalAdd>({
    mode: "onSubmit",
    reValidateMode: "onChange",
    values: initialData ?? initForm,
    resolver: zodResolver(addressSchema),
  })

  const [formLevel, reset] = useFormLevelAddAddressStore((state) => [
    state.level,
    state.reset,
  ])
  const isEdit = !!methods.getValues("id")

  const handleCloseModal = () => {
    methods.reset()
    reset()
    onClose && onClose()
  }

  return (
    <Modal {...props} onOpenChange={handleCloseModal}>
      <FormProvider {...methods}>
        {formLevel === "findAddress" ? (
          <FindAddress
            onClickCancel={handleCloseModal}
            isShowLeafletMap={isShowLeafletMap}
          />
        ) : formLevel === "manual" ? (
          <ManualFormAddressSection
            onClickCancel={handleCloseModal}
            handleSubmit={handleSubmitAddress}
            loading={loading}
            isShowLeafletMap={isShowLeafletMap}
            disabledInputField={disabledInputField}
          />
        ) : formLevel === "googleMaps" ? (
          <GoogleMapsSection
            isEdit={isEdit}
            id={`${isEdit ? "edit" : "add"}-address-googleMaps`}
            isShowLeafletMap={isShowLeafletMap}
            ignoreDistrictVillageFlagEnabled={ignoreDistrictVillageFlagEnabled}
          />
        ) : (
          <DetailAddressSection
            onClickCancel={handleCloseModal}
            handleSubmit={handleSubmitAddress}
            loading={loading}
            id={`${isEdit ? "edit" : "add"}-address-detail`}
            isEdit={isEdit}
            withFormRegion={withFormRegion}
            isShowLeafletMap={isShowLeafletMap}
            disabledInputField={disabledInputField}
          />
        )}
      </FormProvider>
    </Modal>
  )
}

export default ModalAddAddress
