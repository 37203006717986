import dynamic from "next/dynamic"

import { GoogleMaps as GoogleMapsV2 } from "../../../google-v2"
import { type TLatLng as TLatLngV2 } from "../../../google-v2/google-map/type"

const LeafletMap = dynamic(
  () => import("../../../leaflet-map/leaflet-map/index"),
  { ssr: false }
)

type TGoogleMapFormProps = {
  latLng?: TLatLngV2
  onClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
  isShowLeafletMap?: boolean
}

const GoogleMapForm = ({
  latLng,
  onClick,
  isShowLeafletMap,
}: TGoogleMapFormProps) => {
  return (
    <div className="relative mt-2">
      {isShowLeafletMap ? (
        <LeafletMap
          center={latLng}
          isOnlyRender={true}
          mapContainerStyle={{ height: 229 }}
        />
      ) : (
        <GoogleMapsV2
          center={latLng}
          isOnlyRender={true}
          style={{ height: 229 }}
        />
      )}

      <button className="btn-map" onClick={onClick}>
        Atur Lokasi Pinpoint
      </button>
    </div>
  )
}

export default GoogleMapForm
