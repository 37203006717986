"use client"

import { GoogleMap, LoadScriptNext } from "@react-google-maps/api"
import { cx } from "class-variance-authority"
import debounce from "lodash.debounce"
import { useCallback, useEffect, useRef, useState } from "react"
import { Search } from "react-feather"
import { NEXT_PUBLIC_GOOGLE_API_KEY } from "shared-config/clientConfig"
import { Button, useSnackbar } from "shared-ui"
import { formatProvince, formatRegionName } from "shared-utils"

import AddressSection from "./components/AddressSection"
import CurrentLocation from "./components/CurrentLocation"
import MarkerIcon from "./components/MarkerIcon"

import { type TGoogleMaps, type TLatLng } from "./type"
import {
  defaultLatLng,
  defaultZoom,
  mapContainerStyle,
  messageError,
  optionsGoogleMap,
} from "../constants"
import GoogleSearchAutoComplete, {
  type TResultGoogleSearchAutoComplete,
} from "../google-search-autocomplete"
import "./style.css"
import { checkLocationPermission, type TErrorCode } from "../utils"
import useLazyReverseGeocode from "../../leaflet-map/utils/useLazyReverseGeocode"

const GoogleMaps = ({
  center,
  onSubmit,
  loading: loadingProps,
  isOnlyRender,
  style,
  id,
  saveOnDrag,
  submitBtnClassName,
  addressSectionClassName,
  ignoreDistrictVillageFlagEnabled,
}: TGoogleMaps) => {
  const refMap = useRef<google.maps.Map>()
  const [latLng, setLatLng] = useState<TLatLng>(defaultLatLng)
  const [address, setAddress] = useState({
    name: "",
    secondary: "",
    placeId: "",
  })
  const [errorCode, setErrorCode] = useState<Partial<TErrorCode>>()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const { enqueueSnackbar } = useSnackbar()

  const isDisableAllCta = errorCode === "TooManyRequests"
  const isDisableButtonSubmit =
    !latLng || isLoading || loadingProps || !!errorCode

  // NOTES: HANDLE GEOCODING
  const { mutation: getLazyReverseGeocode } = useLazyReverseGeocode({
    ignoreDistrictVillageFlagEnabled,
  })

  const { mutate: getReverseCode } = getLazyReverseGeocode({
    onSuccess(data, variable) {
      setAddress({
        name: data?.villageName,
        secondary: `${data.districtName}, ${formatRegionName(
          data.cityName
        )}, ${formatProvince(data.provinceName)}`,
        placeId: data.villageId,
      })
      setLatLng(variable)

      setErrorCode(data ? undefined : "InvalidRequest")
    },
    onSettled() {
      setTimeout(() => setIsLoading(false), 400)
    },
    onError(error) {
      setErrorCode((error.message as TErrorCode) ?? "InvalidRequest")
    },
  })

  const getAddressFromGeocode = useCallback(
    (location: TLatLng) => {
      setIsLoading(true)
      getReverseCode({ ...location })
    },
    [setAddress, setErrorCode, setIsLoading, setLatLng]
  )

  // NOTES: FOR SET LAT LNG FROM PROPS, THIS SHOULD EXECUTE ONCE (FIRST TIME)
  useEffect(() => {
    if (center) {
      setLatLng(center)
    }
    setIsLoading(true)

    if (!isOnlyRender) {
      void getAddressFromGeocode(center || defaultLatLng)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const debouncedGetAddress = useCallback(
    debounce((centered: TLatLng) => {
      getAddressFromGeocode(centered)
    }, 2000),
    []
  )

  const handleDragEnd = useCallback(() => {
    if (!refMap) return

    const refTemp = refMap?.current as google.maps.MapOptions

    const centered = {
      lat: (refTemp?.center as google.maps.LatLng)?.lat(),
      lng: (refTemp?.center as google.maps.LatLng)?.lng(),
    }
    if (saveOnDrag) saveOnDrag(centered)
    debouncedGetAddress(centered)
  }, [debouncedGetAddress, saveOnDrag])

  const handleRequestLocation = async () => {
    await checkLocationPermission({
      isOnlyRequestPermission: false,
      callbackFn: ({ type, latLng: locationLatLng }) => {
        if (type === "DENIED" || type === "INVALID") {
          return enqueueSnackbar({
            message: messageError[type],
            type: "error",
          })
        }

        if (locationLatLng) {
          if (saveOnDrag) saveOnDrag(locationLatLng)
          void getAddressFromGeocode(locationLatLng)
        }
      },
    })
  }

  const handleOnSelectSearch = useCallback(
    ({
      addressName,
      addressSecondary,
      lat,
      lng,
      placeId,
    }: TResultGoogleSearchAutoComplete) => {
      setAddress({
        name: addressName,
        secondary: addressSecondary,
        placeId,
      })
      setLatLng({
        lat,
        lng,
      })
      setErrorCode(undefined)
      setIsLoading(false)
    },
    []
  )

  const onLoad = useCallback(
    (mapInstance: google.maps.Map) => {
      refMap.current = mapInstance
    },
    [refMap]
  )

  const onUnmount = useCallback(() => {
    refMap.current = undefined
  }, [])

  if (isOnlyRender) {
    return (
      <LoadScriptNext
        googleMapsApiKey={NEXT_PUBLIC_GOOGLE_API_KEY}
        language="id"
        libraries={["places", "geometry"]}
      >
        <GoogleMap
          mapContainerStyle={{ ...mapContainerStyle, ...style }}
          center={latLng || defaultLatLng}
          onLoad={onLoad}
          onUnmount={onUnmount}
          options={optionsGoogleMap}
          zoom={defaultZoom}
        >
          <div className="blockingTag" />
          <MarkerIcon />
        </GoogleMap>
      </LoadScriptNext>
    )
  }

  return (
    <LoadScriptNext
      googleMapsApiKey={NEXT_PUBLIC_GOOGLE_API_KEY}
      language="id"
      libraries={["places", "geometry"]}
    >
      <div>
        <GoogleMap
          mapContainerStyle={{ ...mapContainerStyle, ...style }}
          center={latLng || defaultLatLng}
          onLoad={onLoad}
          onUnmount={onUnmount}
          onDragStart={() => setIsLoading(true)}
          onDragEnd={handleDragEnd}
          options={optionsGoogleMap}
          zoom={defaultZoom}
        >
          <div className="headerContainer flex gap-x-4">
            <CurrentLocation
              onClickCurrentLocation={handleRequestLocation}
              id={id}
              disabled={isDisableAllCta}
            />
            <div className="w-full max-w-[368px]">
              <GoogleSearchAutoComplete
                id={id}
                onSelect={handleOnSelectSearch}
                placeholder="Cari ulang alamat"
                iconLeft={<Search color="#344054" width={20} height={20} />}
                iconStyle={{
                  iconLeft: { padding: "0px 14px" },
                }}
                isClearFieldOnBlur={true}
                withChevron={false}
                disabled={isDisableAllCta}
              />
            </div>
          </div>
          {!isLoading && (
            <div className="tooltip-container">
              <div className="tooltip text-primary25 text-sm">
                Alamat Anda di sini
              </div>
            </div>
          )}
          <MarkerIcon />
        </GoogleMap>
        <div className={cx("py-4", addressSectionClassName)}>
          <AddressSection
            isLoading={isLoading}
            errorCode={errorCode}
            address={address}
          />
        </div>
        {!saveOnDrag && (
          <div className={submitBtnClassName}>
            <Button
              id={`${id}-save-pinpoint`}
              className="w-full"
              onClick={() => {
                if (!latLng) return
                onSubmit?.({
                  ...latLng,
                  addressName: address.name,
                  addressSecondary: address.secondary,
                  placeId: address.placeId,
                })
              }}
              disabled={isDisableButtonSubmit}
              isLoading={loadingProps}
              type="button"
            >
              Pilih Lokasi & Lanjut Isi Alamat
            </Button>
          </div>
        )}
      </div>
    </LoadScriptNext>
  )
}

export default GoogleMaps
