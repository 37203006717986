"use client"

import { useQuery } from "@tanstack/react-query"
import dynamic from "next/dynamic"
import { useState } from "react"
import { useFormContext } from "react-hook-form"
import { Spinner, useSnackbar } from "shared-ui"

import useFormLevelAddAddressStore from "../../../address/hooks/useFormLevelAddAddressStore"
import { type GetAddressReverseGeocodeQuery } from "../../../federatedGql/graphql"
import {
  GoogleMaps as GoogleMapsV2,
  type TErrorCode,
  getErrorSnackbarFromErrCode,
} from "../../../google-v2"
import { type TParamsOnSubmit as TParamsOnSubmitV2 } from "../../../google-v2/google-map/type"
import { federatedGqlClient } from "../../../utils/graphqlClient"
import HeaderForm from "../components/HeaderForm"
import { type TParamsOnSubmit } from "../components/google/google-map/type"
import { GET_ADDRESS_REVERSE_GEOCODE } from "../federated/queries"
import { type TFormModalAdd, type TLocationPinPoint } from "../type"

const LeafletMap = dynamic(
  () => import("../../../leaflet-map/leaflet-map/index"),
  {
    ssr: false,
    loading: () => (
      <div className="flex w-full justify-center">
        <Spinner />
      </div>
    ),
  }
)

const GoogleMapsSection = ({
  isEdit,
  id = "add-address-googleMaps",
  isShowLeafletMap = false,
  ignoreDistrictVillageFlagEnabled,
}: {
  isEdit?: boolean
  id?: string
  isShowLeafletMap?: boolean
  ignoreDistrictVillageFlagEnabled?: boolean
}) => {
  const { enqueueSnackbar } = useSnackbar()
  const { watch, setValue } = useFormContext<TFormModalAdd>()
  const [latLng, setLatLng] = useState<TLocationPinPoint>()
  const [prevLevelForm, setFormLevel] = useFormLevelAddAddressStore((state) => [
    state.previous,
    state.setForm,
  ])

  const latLngPinPoint = watch("detailAddress.googleMaps.latLng")
  const handleSubmit = (data: TParamsOnSubmit | TParamsOnSubmitV2) => {
    setValue("detailAddress.googleMaps.addressName", data.addressName)
    setValue(
      "detailAddress.googleMaps.addressSecondary",
      ignoreDistrictVillageFlagEnabled
        ? data.addressName
        : data.addressSecondary
    )
    setValue("detailAddress.googleMaps.placeId", data.placeId)
    // NOTES: Enable QUERY REVERSE GEOCODE THEN GO TO DETAIL FORM LEVEL
    setLatLng({
      lat: data.lat,
      lng: data.lng,
    })
  }

  const { isFetching } = useQuery<GetAddressReverseGeocodeQuery>({
    queryKey: ["GET_ADDRESS_REVERSE_GEOCODE", latLng],
    queryFn: async ({ queryKey }) => {
      return federatedGqlClient.request(GET_ADDRESS_REVERSE_GEOCODE, {
        lat: (queryKey[1] as TLocationPinPoint).lat,
        lng: (queryKey[1] as TLocationPinPoint).lng,
      })
    },
    onSuccess: (response) => {
      if (latLng) {
        setValue("detailAddress.googleMaps.latLng", latLng)
      }

      if (response.reverseGeocode.__typename === "ReverseGeocode") {
        const reverseGeocode = response.reverseGeocode
        if (prevLevelForm.level === "manual") {
          setValue(
            "detailAddress.googleMaps.districtAreaCode",
            reverseGeocode.districtAreaCode
          )
          setFormLevel("manual")
          return
        }
        setValue("detailAddress.province", {
          name: reverseGeocode.provinceName,
          id: reverseGeocode.provinceId,
          areaCode: reverseGeocode.provinceAreaCode,
        })
        setValue("detailAddress.city", {
          name: reverseGeocode.cityName,
          id: reverseGeocode.cityId,
          areaCode: reverseGeocode.cityAreaCode,
        })
        setValue("detailAddress.district", {
          areaCode: ignoreDistrictVillageFlagEnabled
            ? ""
            : reverseGeocode.districtAreaCode,
          id: ignoreDistrictVillageFlagEnabled ? "" : reverseGeocode.districtId,
          name: ignoreDistrictVillageFlagEnabled
            ? ""
            : reverseGeocode.districtName,
        })
        setValue("detailAddress.village", {
          areaCode: ignoreDistrictVillageFlagEnabled
            ? ""
            : reverseGeocode.villageAreaCode,
          id: ignoreDistrictVillageFlagEnabled ? "" : reverseGeocode.villageId,
          name: ignoreDistrictVillageFlagEnabled
            ? ""
            : reverseGeocode.villageName,
        })
        setValue("detailAddress.postalCode", {
          areaCode: "",
          id: "",
          name: ignoreDistrictVillageFlagEnabled
            ? ""
            : reverseGeocode.villagePostalCode,
        })
        setValue(
          "detailAddress.googleMaps.districtAreaCode",
          ignoreDistrictVillageFlagEnabled
            ? ""
            : reverseGeocode.districtAreaCode
        )
        setFormLevel("detailAddress")
      } else {
        enqueueSnackbar({
          message: getErrorSnackbarFromErrCode(
            response.reverseGeocode.code as TErrorCode
          ),
          color: "error",
        })
      }
    },
    onError: () => {
      enqueueSnackbar({
        message:
          "Gagal simpan dan lanjutkan, silahkan geser pinpoint lalu coba lagi",
        color: "error",
      })
    },
    enabled: latLng !== undefined,
    refetchOnWindowFocus: false,
  })

  return (
    <>
      <HeaderForm
        className="mb-6"
        onClickBack={() =>
          setFormLevel(
            prevLevelForm.canGoBack ? prevLevelForm.level : "findAddress"
          )
        }
        isEdit={isEdit}
        id={id}
      />

      {isShowLeafletMap ? (
        <LeafletMap
          mapContainerStyle={{ height: 381 }}
          center={latLngPinPoint}
          onSubmit={handleSubmit}
          loading={isFetching}
          id={id}
          ignoreDistrictVillageFlagEnabled={ignoreDistrictVillageFlagEnabled}
        />
      ) : (
        <GoogleMapsV2
          style={{ height: 381 }}
          center={latLngPinPoint}
          onSubmit={handleSubmit}
          loading={isFetching}
          id={id}
          ignoreDistrictVillageFlagEnabled={ignoreDistrictVillageFlagEnabled}
        />
      )}
    </>
  )
}

export default GoogleMapsSection
