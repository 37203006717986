/* eslint-disable */
import * as types from './graphql';
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';

/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel or swc plugin for production.
 */
const documents = {
    "\n  query getAddressReverseGeocode($lat: Float!, $lng: Float!) {\n    reverseGeocode(query: { latitude: $lat, longitude: $lng }) {\n      ... on ReverseGeocode {\n        __typename\n        cityAreaCode\n        cityId\n        cityName\n        districtAreaCode\n        districtId\n        districtName\n        provinceAreaCode\n        provinceId\n        provinceName\n        villageAreaCode\n        villageId\n        villageName\n        villagePostalCode\n      }\n      ... on GenericError {\n        __typename\n        code\n        message\n        reqId\n      }\n    }\n  }\n": types.GetAddressReverseGeocodeDocument,
    "\n  query getAddressReverseGeocodeDev(\n    $lat: Float!\n    $lng: Float!\n    $enrichData: Boolean\n  ) {\n    reverseGeocode(\n      query: { latitude: $lat, longitude: $lng, enrichData: $enrichData }\n    ) {\n      ... on ReverseGeocode {\n        __typename\n        cityAreaCode\n        cityId\n        cityName\n        districtAreaCode\n        districtId\n        districtName\n        provinceAreaCode\n        provinceId\n        provinceName\n        villageAreaCode\n        villageId\n        villageName\n        villagePostalCode\n      }\n      ... on GenericError {\n        __typename\n        code\n        message\n        reqId\n      }\n    }\n  }\n": types.GetAddressReverseGeocodeDevDocument,
    "\n  query GetSearchRegion(\n    $level: RegionLevel\n    $search: String\n    $parentIds: [String!]\n    $pagination: RegionPagination!\n  ) {\n    getSearchRegionV2(\n      query: {\n        filter: { level: $level, search: $search, parentIds: $parentIds }\n        pagination: $pagination\n      }\n    ) {\n      ... on RegionSearchList {\n        items {\n          areaCode\n          id\n          level\n          name\n          parentId\n          postalCode\n        }\n        currentPage\n        lastPage\n        perPage\n        total\n      }\n      ... on GenericError {\n        __typename\n        code\n        message\n        reqId\n      }\n    }\n  }\n": types.GetSearchRegionDocument,
    "\n  query getAutocomplete($input: String!) {\n    autocomplete(input: { input: $input }) {\n      ... on Autocomplete {\n        __typename\n        result {\n          mainText\n          placeId\n          secondaryText\n        }\n      }\n      ... on GenericError {\n        __typename\n        code\n        message\n        reqId\n      }\n    }\n  }\n": types.GetAutocompleteDocument,
    "\n  query getAddressFromGeocode(\n    $latitude: Float!\n    $longitude: Float!\n    $language: String!\n  ) {\n    geocode(\n      input: { latitude: $latitude, longitude: $longitude, language: $language }\n    ) {\n      ... on Geocode {\n        __typename\n        result {\n          types\n          placeId\n          addressComponents {\n            longName\n            shortName\n            types\n          }\n        }\n      }\n      ... on GenericError {\n        __typename\n        code\n        message\n        reqId\n      }\n    }\n  }\n": types.GetAddressFromGeocodeDocument,
    "\n  query getPlaceDetail($placeId: String!) {\n    placeDetails(input: { placeId: $placeId }) {\n      ... on PlaceDetails {\n        __typename\n        result {\n          location {\n            lat\n            lng\n          }\n          addressComponents {\n            longName\n            types\n            shortName\n          }\n          placeId\n          types\n        }\n      }\n      ... on GenericError {\n        __typename\n        code\n        message\n        reqId\n      }\n    }\n  }\n": types.GetPlaceDetailDocument,
};

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 *
 *
 * @example
 * ```ts
 * const query = graphql(`query GetUser($id: ID!) { user(id: $id) { name } }`);
 * ```
 *
 * The query argument is unknown!
 * Please regenerate the types.
 */
export function graphql(source: string): unknown;

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query getAddressReverseGeocode($lat: Float!, $lng: Float!) {\n    reverseGeocode(query: { latitude: $lat, longitude: $lng }) {\n      ... on ReverseGeocode {\n        __typename\n        cityAreaCode\n        cityId\n        cityName\n        districtAreaCode\n        districtId\n        districtName\n        provinceAreaCode\n        provinceId\n        provinceName\n        villageAreaCode\n        villageId\n        villageName\n        villagePostalCode\n      }\n      ... on GenericError {\n        __typename\n        code\n        message\n        reqId\n      }\n    }\n  }\n"): (typeof documents)["\n  query getAddressReverseGeocode($lat: Float!, $lng: Float!) {\n    reverseGeocode(query: { latitude: $lat, longitude: $lng }) {\n      ... on ReverseGeocode {\n        __typename\n        cityAreaCode\n        cityId\n        cityName\n        districtAreaCode\n        districtId\n        districtName\n        provinceAreaCode\n        provinceId\n        provinceName\n        villageAreaCode\n        villageId\n        villageName\n        villagePostalCode\n      }\n      ... on GenericError {\n        __typename\n        code\n        message\n        reqId\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query getAddressReverseGeocodeDev(\n    $lat: Float!\n    $lng: Float!\n    $enrichData: Boolean\n  ) {\n    reverseGeocode(\n      query: { latitude: $lat, longitude: $lng, enrichData: $enrichData }\n    ) {\n      ... on ReverseGeocode {\n        __typename\n        cityAreaCode\n        cityId\n        cityName\n        districtAreaCode\n        districtId\n        districtName\n        provinceAreaCode\n        provinceId\n        provinceName\n        villageAreaCode\n        villageId\n        villageName\n        villagePostalCode\n      }\n      ... on GenericError {\n        __typename\n        code\n        message\n        reqId\n      }\n    }\n  }\n"): (typeof documents)["\n  query getAddressReverseGeocodeDev(\n    $lat: Float!\n    $lng: Float!\n    $enrichData: Boolean\n  ) {\n    reverseGeocode(\n      query: { latitude: $lat, longitude: $lng, enrichData: $enrichData }\n    ) {\n      ... on ReverseGeocode {\n        __typename\n        cityAreaCode\n        cityId\n        cityName\n        districtAreaCode\n        districtId\n        districtName\n        provinceAreaCode\n        provinceId\n        provinceName\n        villageAreaCode\n        villageId\n        villageName\n        villagePostalCode\n      }\n      ... on GenericError {\n        __typename\n        code\n        message\n        reqId\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query GetSearchRegion(\n    $level: RegionLevel\n    $search: String\n    $parentIds: [String!]\n    $pagination: RegionPagination!\n  ) {\n    getSearchRegionV2(\n      query: {\n        filter: { level: $level, search: $search, parentIds: $parentIds }\n        pagination: $pagination\n      }\n    ) {\n      ... on RegionSearchList {\n        items {\n          areaCode\n          id\n          level\n          name\n          parentId\n          postalCode\n        }\n        currentPage\n        lastPage\n        perPage\n        total\n      }\n      ... on GenericError {\n        __typename\n        code\n        message\n        reqId\n      }\n    }\n  }\n"): (typeof documents)["\n  query GetSearchRegion(\n    $level: RegionLevel\n    $search: String\n    $parentIds: [String!]\n    $pagination: RegionPagination!\n  ) {\n    getSearchRegionV2(\n      query: {\n        filter: { level: $level, search: $search, parentIds: $parentIds }\n        pagination: $pagination\n      }\n    ) {\n      ... on RegionSearchList {\n        items {\n          areaCode\n          id\n          level\n          name\n          parentId\n          postalCode\n        }\n        currentPage\n        lastPage\n        perPage\n        total\n      }\n      ... on GenericError {\n        __typename\n        code\n        message\n        reqId\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query getAutocomplete($input: String!) {\n    autocomplete(input: { input: $input }) {\n      ... on Autocomplete {\n        __typename\n        result {\n          mainText\n          placeId\n          secondaryText\n        }\n      }\n      ... on GenericError {\n        __typename\n        code\n        message\n        reqId\n      }\n    }\n  }\n"): (typeof documents)["\n  query getAutocomplete($input: String!) {\n    autocomplete(input: { input: $input }) {\n      ... on Autocomplete {\n        __typename\n        result {\n          mainText\n          placeId\n          secondaryText\n        }\n      }\n      ... on GenericError {\n        __typename\n        code\n        message\n        reqId\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query getAddressFromGeocode(\n    $latitude: Float!\n    $longitude: Float!\n    $language: String!\n  ) {\n    geocode(\n      input: { latitude: $latitude, longitude: $longitude, language: $language }\n    ) {\n      ... on Geocode {\n        __typename\n        result {\n          types\n          placeId\n          addressComponents {\n            longName\n            shortName\n            types\n          }\n        }\n      }\n      ... on GenericError {\n        __typename\n        code\n        message\n        reqId\n      }\n    }\n  }\n"): (typeof documents)["\n  query getAddressFromGeocode(\n    $latitude: Float!\n    $longitude: Float!\n    $language: String!\n  ) {\n    geocode(\n      input: { latitude: $latitude, longitude: $longitude, language: $language }\n    ) {\n      ... on Geocode {\n        __typename\n        result {\n          types\n          placeId\n          addressComponents {\n            longName\n            shortName\n            types\n          }\n        }\n      }\n      ... on GenericError {\n        __typename\n        code\n        message\n        reqId\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query getPlaceDetail($placeId: String!) {\n    placeDetails(input: { placeId: $placeId }) {\n      ... on PlaceDetails {\n        __typename\n        result {\n          location {\n            lat\n            lng\n          }\n          addressComponents {\n            longName\n            types\n            shortName\n          }\n          placeId\n          types\n        }\n      }\n      ... on GenericError {\n        __typename\n        code\n        message\n        reqId\n      }\n    }\n  }\n"): (typeof documents)["\n  query getPlaceDetail($placeId: String!) {\n    placeDetails(input: { placeId: $placeId }) {\n      ... on PlaceDetails {\n        __typename\n        result {\n          location {\n            lat\n            lng\n          }\n          addressComponents {\n            longName\n            types\n            shortName\n          }\n          placeId\n          types\n        }\n      }\n      ... on GenericError {\n        __typename\n        code\n        message\n        reqId\n      }\n    }\n  }\n"];

export function graphql(source: string) {
  return (documents as any)[source] ?? {};
}

export type DocumentType<TDocumentNode extends DocumentNode<any, any>> = TDocumentNode extends DocumentNode<  infer TType,  any>  ? TType  : never;