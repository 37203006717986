"use client"

import { useState } from "react"
import {
  type Control,
  type FieldPath,
  type UseFormClearErrors,
  useWatch,
} from "react-hook-form"
import { useDebounce } from "shared-utils"

import { type Region, type RegionLevel } from "../../../../federatedGql/graphql"
import useGetRegionSearchPagination from "../../../hooks/useGetRegionSearchPagination"
import { type TFormModalAdd, type TRegion } from "../../type"
import InputSelect from "../google/input-select"
import {
  type TInputSelectProps,
  type TOption,
} from "../google/input-select/type"

type TInputFieldSelectProps = Omit<TInputSelectProps, "onSelect" | "name"> & {
  control: Control<TFormModalAdd>
  name: FieldPath<TFormModalAdd>
  label?: string
  watchValues?: Array<FieldPath<TFormModalAdd>>
  onSelect: (
    val: TOption,
    data?: Pick<Region, "parentId" | "postalCode">
  ) => void
  level?: RegionLevel
  errorMessage?: string
  clearErrors: UseFormClearErrors<TFormModalAdd>
}

export const PER_PAGE = 10

const defaultPage = {
  page: 1,
  perPage: PER_PAGE,
}

const InputFieldSelect = ({
  control,
  name,
  label,
  watchValues = [],
  onChange,
  onSelect,
  level,
  errorMessage,
  clearErrors,
  disabled,
  id,
  ...restProps
}: TInputFieldSelectProps) => {
  const [data, setData] = useState<TOption[]>([])
  const [isEnableFetch, setIsEnableFetch] = useState<boolean>(false)
  const values = useWatch({
    control,
    name: [name, ...watchValues],
  })
  const inputValue = values?.[0] ?? ""
  const watchValue = values?.[1] as TRegion
  const debounceValue = useDebounce(inputValue, 1000)

  const {
    isFetching,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isInitialLoading,
    isError,
  } = useGetRegionSearchPagination({
    variables: {
      level,
      search: debounceValue as string,
      parentIds: watchValue?.id ?? null,
      pagination: defaultPage,
    },
    options: {
      enabled:
        (!!debounceValue || Boolean(watchValue?.id)) &&
        !!level &&
        isEnableFetch,
    },
    onSuccess(result, isPagination) {
      const specOptions: TOption[] =
        result?.items?.map((region) => ({
          label: region.name || "",
          value: region.id || "",
          areaCode: region.areaCode || "",
          item: { ...region },
        })) || []

      setData((prev) => {
        if (isPagination) return [...prev, ...specOptions]
        return specOptions
      })
    },
  })

  const handleChange = (val: string) => {
    if (onChange) onChange(val)
    if (errorMessage && (val !== "" || val !== undefined)) clearErrors(name)
  }

  const handleLoadMore = (event: React.UIEvent<HTMLUListElement>) => {
    const target = event.currentTarget
    const scrolling = Math.floor(target.scrollHeight - target.scrollTop)
    if (
      scrolling <= target.clientHeight &&
      hasNextPage &&
      !isFetchingNextPage
    ) {
      fetchNextPage()
    }
  }

  return (
    <div className="w-full">
      <InputSelect
        label={label}
        value={inputValue as string}
        onChange={handleChange}
        onSelect={(opt: TOption) => {
          onSelect(opt, opt.item)
          if (errorMessage) clearErrors(name)
        }}
        disabled={disabled || (!!watchValues.length && !watchValue?.name)}
        options={data}
        isLoading={isFetching}
        isInitialLoading={isInitialLoading}
        onFocus={() => setIsEnableFetch(true)}
        errorMessage={errorMessage}
        id={id}
        onScrollItems={handleLoadMore}
        isErrorFetch={isError}
        {...restProps}
      />
    </div>
  )
}

export default InputFieldSelect
