import { MapPin } from "react-feather"
import { useFormContext } from "react-hook-form"
import { Button, Checkbox, Spinner } from "shared-ui"

import FormRegionAutoSuggest, {
  TFormRegionAutoSuggestProps,
} from "./FormRegionAutoSuggest"
import { onPromise } from "../../../address/utils/onPromise"
import useFormLevelAddAddressStore from "../../hooks/useFormLevelAddAddressStore"
import GoogleMapForm from "../components/GoogleMapForm"
import HeaderForm from "../components/HeaderForm"
import { InputField, InputFieldArea } from "../components/input"
import { type TFormModalAdd } from "../type"
import "../../style.css"

type TDetailAddressSectionProps = {
  handleSubmit: (address: TFormModalAdd) => void
  onClickCancel: (val: boolean) => void
  loading?: boolean
  id?: string
  isEdit?: boolean
  withFormRegion?: boolean
  isShowLeafletMap?: boolean
} & TFormRegionAutoSuggestProps

const DetailAddressSection = ({
  onClickCancel,
  handleSubmit,
  loading,
  id = "add-address-detail",
  isEdit,
  withFormRegion = true,
  isShowLeafletMap = false,
  disabledInputField,
}: TDetailAddressSectionProps) => {
  const {
    setValue,
    handleSubmit: onSubmitHookForm,
    watch,
    control,
    formState: { errors: formErrors, defaultValues },
    clearErrors,
    reset,
  } = useFormContext<TFormModalAdd>()
  const setFormLevel = useFormLevelAddAddressStore((state) => state.setForm)

  const [latLng, addressMaps, isPrimaryAddress] = watch([
    "detailAddress.googleMaps.latLng",
    "detailAddress.googleMaps.addressSecondary",
    "detailAddress.isPrimaryAddress",
  ])

  return (
    <>
      <HeaderForm
        className="mb-6"
        onClickBack={() => {
          setFormLevel("googleMaps", false)
          clearErrors()
          reset()
        }}
        isEdit={isEdit}
        id={id}
      />
      <h5 className="text-tertiary500 mb-2 mt-4 text-base font-bold">
        Lengkapi Detail Alamat
      </h5>
      <fieldset
        className="rounded-4 border-tertiary200 bg-tertiary25 mb-4 flex items-center gap-x-4 border
border-solid px-3 py-2"
      >
        <MapPin size={18} className="text-tertiary200" />
        <p className="text-tertiary200 text-sm">{addressMaps}</p>
      </fieldset>
      <fieldset className="mb-4">
        <label className="text-tertiary500 text-sm font-semibold">
          Atur Lokasi
        </label>
        <GoogleMapForm
          latLng={latLng}
          onClick={(e) => {
            e.preventDefault()
            setFormLevel("googleMaps")
            clearErrors()
          }}
          isShowLeafletMap={isShowLeafletMap}
        />
      </fieldset>
      <fieldset className="mb-2">
        <InputFieldArea
          id={`${id}-input-address`}
          label="Alamat Lengkap"
          placeholder="Masukkan nama jalan, nomor rumah atau kantor, dan lainnya."
          maxLength={100}
          control={control}
          name="detailAddress.address"
          errorMessage={formErrors.detailAddress?.address?.message}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              e.preventDefault()
            }
          }}
        />
      </fieldset>
      {withFormRegion && (
        <FormRegionAutoSuggest disabledInputField={disabledInputField} />
      )}
      <InputFieldArea
        id={`${id}-input-notes`}
        label="Catatan Pengiriman"
        placeholder="Warna rumah, patokan, pesanan khusus dan lainnya."
        labelOptional
        maxLength={100}
        control={control}
        name="detailAddress.notes"
        errorMessage={formErrors.detailAddress?.notes?.message}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            e.preventDefault()
          }
        }}
      />
      <InputField
        id={`${id}-input-label`}
        label="Label Alamat"
        placeholder="Contoh: rumah, apartemen, kantor, dan lainnya"
        control={control}
        name="detailAddress.label"
        errorMessage={formErrors.detailAddress?.label?.message}
      />
      <InputField
        id={`${id}-input-name`}
        label="Nama Penerima"
        placeholder="Masukkan nama penerima"
        control={control}
        name="detailAddress.name"
        errorMessage={formErrors.detailAddress?.name?.message}
      />
      <InputField
        id={`${id}-input-phone`}
        label="Nomor Telepon Penerima"
        placeholder="81234567890"
        control={control}
        name="detailAddress.phone"
        errorMessage={formErrors.detailAddress?.phone?.message}
        addonLeft="+62"
      />
      <fieldset>
        <Checkbox
          id={`${id}-checkbox-mainAddress`}
          checked={isPrimaryAddress}
          onChange={(e) =>
            setValue("detailAddress.isPrimaryAddress", e.currentTarget.checked)
          }
          disabled={Boolean(defaultValues?.detailAddress?.isPrimaryAddress)}
        >
          Jadikan sebagai alamat utama
        </Checkbox>
      </fieldset>
      <fieldset className="mb-3 mt-10 flex gap-x-4">
        <Button
          id={`${id}-btn-cancel`}
          variant="outline2"
          className="w-full"
          onClick={() => onClickCancel(true)}
        >
          Batal
        </Button>
        <Button
          id={`${id}-btn-save`}
          className="w-full"
          onClick={onPromise(onSubmitHookForm(handleSubmit))}
          disabled={loading}
          IconRight={() =>
            loading ? <Spinner color="primary" size="small" /> : <></>
          }
        >
          Simpan
        </Button>
      </fieldset>
    </>
  )
}

export default DetailAddressSection
