import { z } from "zod"

export const addressSchema = z.object({
  detailAddress: z.object({
    label: z.string().refine(
      (data) => {
        if (data.length > 0 && data.length <= 20) {
          return true
        }
        return false
      },
      {
        message: "Label alamat minimal 1 karakter dan maksimal 20 karakter",
      }
    ),
    name: z.string().refine(
      (data) => {
        if (data.length > 0 && data.length <= 20) {
          return true
        }
        return false
      },
      {
        message: "Nama penerima minimal 1 karakter dan maksimal 20 karakter",
      }
    ),
    phone: z
      .string()
      .min(1, "Nomor telepon penerima harus diisi")
      .regex(
        /^[0-9]+$/,
        "Nomor telepon penerima hanya boleh diisi dengan angka"
      )
      .refine(
        (data) => {
          let phoneNumberData = data
          if (data.startsWith("62") || data.startsWith("0")) {
            phoneNumberData = data.replace(/^62|0/, "")
          }

          if (phoneNumberData.length > 8 && phoneNumberData.length <= 12) {
            return true
          }
          return false
        },
        {
          message:
            "Nomor telepon penerima minimal 9 karakter dan maksimal 12 karakter",
        }
      ),
    province: z
      .object({
        id: z.string(),
        name: z.string(),
        areaCode: z.string(),
      })
      .refine((data) => data.name !== "", {
        message: "Provinsi tidak valid, mohon hubungi Pusat Bantuan",
        path: ["name"],
      })
      .refine((data) => data.id !== "", {
        message: "Provinsi tidak valid, mohon hubungi Pusat Bantuan",
        path: ["name"],
      }),
    city: z
      .object({
        id: z.string(),
        name: z.string(),
        areaCode: z.string(),
      })
      .refine((data) => data.name !== "", {
        message: "Kota/Kabupaten tidak valid, mohon hubungi Pusat Bantuan",
        path: ["name"],
      })
      .refine((data) => data.id !== "", {
        message: "Kota/Kabupaten tidak valid, mohon hubungi Pusat Bantuan",
        path: ["name"],
      }),
    district: z
      .object({
        id: z.string(),
        name: z.string(),
        areaCode: z.string(),
      })
      .refine((data) => data.name !== "", {
        message: "Kecamatan tidak valid, mohon hubungi Pusat Bantuan",
        path: ["name"],
      })
      .refine((data) => data.id !== "", {
        message: "Kecamatan tidak valid, mohon hubungi Pusat Bantuan",
        path: ["name"],
      }),
    village: z
      .object({
        id: z.string(),
        name: z.string(),
        areaCode: z.string(),
      })
      .refine((data) => data.name !== "", {
        message: "Kelurahan tidak valid, mohon hubungi Pusat Bantuan",
        path: ["name"],
      })
      .refine((data) => data.id !== "", {
        message: "Kelurahan tidak valid, mohon hubungi Pusat Bantuan",
        path: ["name"],
      }),
    postalCode: z
      .object({
        id: z.string(),
        name: z.string(),
        areaCode: z.string(),
      })
      .refine((data) => data.name !== "", {
        message: "Kode Pos tidak valid, mohon hubungi Pusat Bantuan",
        path: ["name"],
      }),
    googleMaps: z
      .object({
        latLng: z
          .object({
            lat: z.number().nullish(),
            lng: z.number().nullish(),
          })
          .optional(),
        addressName: z.string(),
        placeId: z.string(),
        addressSecondary: z.string(),
        districtAreaCode: z.string().nullish(),
      })
      .optional(),
    address: z
      .string()
      .regex(
        /^[A-Za-z0-9#&'.=+,-/() ]+$/,
        "Tidak diperbolehkan menggunakan enter dan simbol selain: # & ' . - = + , ( ) /"
      )
      .refine(
        (data) => {
          if (data.length > 0 && data.length <= 100) {
            return true
          }
          return false
        },
        {
          message:
            "Alamat Lengkap minimal 1 karakter dan maksimal 100 karakter",
        }
      ),
    notes: z
      .string()
      .regex(
        /^[A-Za-z0-9#&'.=+,-/() ]+$/,
        "Tidak diperbolehkan menggunakan enter dan simbol selain: # & ' . - = + , ( ) /"
      )
      .max(100, "Catatan maksimal 100 karakter")
      .optional()
      .nullable()
      .or(z.literal("")),
    isPrimaryAddress: z.boolean(),
  }),
})
