/**
 * Formats region names to proper case with standardized prefixes
 * Example: "KAB. KAPUAS HULU" → "Kab. Kapuas Hulu"
 */
const formatRegionName = (name: string): string => {
  if (!name?.trim()) return ""

  // Handle special cases (DKI, DI, etc.)
  const specialAcronyms = ["dki", "di", "diy", "ntb", "ntt"]

  return name
    .toLowerCase()
    .replace(/kabupaten|kab\./gi, "Kab.")
    .replace(/kota/gi, "Kota")
    .replace(/adm/gi, "Adm")
    .split(" ")
    .map((word) =>
      specialAcronyms.includes(word)
        ? word.toUpperCase()
        : word.charAt(0).toUpperCase() + word.slice(1)
    )
    .join(" ")
}

export default formatRegionName
